import Vue from 'vue'
import VueRouter from 'vue-router'
import tft from "@/views/tft.vue";
import lineupList from "@/views/LineUpList.vue";
import createLineup from "@/views/createLineup.vue";
import home from "@/views/home.vue";
import homePage from "@/views/homePage.vue";
import answerBook from "@/views/answerBook.vue";
import question from "@/views/ManyWhy.vue";
import constellation from "@/views/Constellation.vue";
import whatToEat from "@/views/whatToEat.vue";
import wechatWallet from "@/views/wechatWallet.vue";
import criminalLaw from "@/views/law.vue";
import gossip from "@/views/gossip.vue";
import animatedCharacters from "@/views/animatedCharacters.vue";
import notes from "@/views/notes.vue";
import postal from "@/views/postal.vue";
import admin from "@/views/admin.vue";
import BackOffice from "@/views/BackOffice.vue";
import calculator from "@/views/calculator.vue";
import todoList from "@/views/todoList.vue";
import familyMotto from "@/views/familyMotto.vue";
import bill from "@/views/bill.vue";
import copy from "@/views/copy.vue";
import newTft from "@/views/tft.vue";
import introductory from "@/views/Introductory.vue";
import word from "@/views/word.vue";
import game from "@/views/game.vue";
import slotMachine from "@/views/slotMachine.vue";
import log from "@/views/log.vue";
import QRCode from "@/views/QRCode.vue";
import scanUpload from "@/views/scanUpload.vue";
import xls from "@/views/xls.vue";
import wiki from "@/views/wiki.vue";
import notFound from "@/views/notFound.vue";


Vue.use(VueRouter)

const routes = [
    {path: '/', name: 'home', component: home},
    {path: '/lineup', name: 'lineup', component: lineupList},
    {path: '/tft', name: 'tft', component: tft},
    {path: '/add', name: 'createLineup', component: createLineup},
    {path: '/answer', name: 'answerBook', component: answerBook},
    {path: '/question', name: 'question', component: question},
    {path: '/constellation', name: 'constellation', component: constellation},
    {path: '/eat', name: 'whatToEat', component: whatToEat},
    {path: '/wechat', name: 'wechatWallet', component: wechatWallet},
    {path: '/law', name: 'criminalLaw', component: criminalLaw},
    {path: '/gossip', name: 'gossip', component: gossip},
    {path: '/characters', name: 'ultraman', component: animatedCharacters},
    {path: '/notes', name: 'note', component: notes},
    {path: '/postal', name: 'postal', component: postal},
    {path: '/admin', name: 'admin', component: admin},
    {path: '/backOffice', name: 'backOffice', component: BackOffice},
    {path: '/calculator', name: 'calculator', component: calculator},
    {path: '/todoList', name: 'todoList', component: todoList},
    {path: '/familyMotto', name: 'familyMotto', component: familyMotto},
    {path: '/bill', name: 'bill', component: bill},
    {path: '/copy', name: 'copy', component: copy},
    {path: '/newTft', name: 'newTft', component: newTft},
    {path: '/introductory', name: 'introductory', component: introductory},
    {path: '/word', name: 'new', component: word},
    {path: '/game', name: 'game', component: game},
    {path: '/machine', name: 'machine', component: slotMachine},
    {path: '/log', name: 'log', component: log},
    {path: '/QRCode', name: 'QRCode', component: QRCode},
    {path: '/scanUpload', name: 'scanUpload', component: scanUpload},
    {path: '/homePage', name: 'homePage', component: homePage},
    {path: '/xls1', name: 'xls', component: xls},
    {path: '/wiki1', name: 'wiki', component: wiki},
    {path: '/wiki', name: 'notFound', component: notFound},
    {path: '/xls', name: 'notFound', component: notFound},

]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
