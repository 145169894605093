<template>
    <div class="not-found">
        <div class="content">
            <h1>NotFound</h1>
            <h2>页面未找到</h2>
            <p>此页面因相关因素已被关闭。</p>
            <button @click="goHome" class="home-button">返回首页</button>
        </div>
        <div class="background">
            <div v-for="n in 50" :key="n" class="particle"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    mounted() {
        document.title = 'NotFound - 页面未找到';
        this.animateParticles();
    },
    methods: {
        goHome() {
            this.$router.push('/');
        },
        animateParticles() {
            const particles = document.querySelectorAll('.particle');
            particles.forEach(particle => {
                const duration = Math.random() * 5 + 5;
                const x = Math.random() * 100;
                const y = Math.random() * 100;
                particle.style.setProperty('--duration', `${duration}s`);
                particle.style.setProperty('--x', `${x}vw`);
                particle.style.setProperty('--y', `${y}vh`);
            });
        }
    }
};
</script>

<style scoped>
html, body, #app {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
    color: #000;
    overflow: hidden;
    position: relative;
    font-family: 'SF Pro SC', 'SF Pro Text', 'Helvetica Neue', Arial, sans-serif;
}

.content {
    text-align: center;
    z-index: 1;
}

h1 {
    font-size: 6rem;
    margin: 0;
    color: #000;
    font-weight: 700;
}

h2 {
    font-size: 3rem;
    margin: 0.5rem 0;
    color: #666;
}

p {
    font-size: 1.2rem;
    color: #888;
    margin-bottom: 2rem;
}

.home-button {
    padding: 1rem 2rem;
    font-size: 1rem;
    background-color: #007AFF;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    font-weight: 500;
}

.home-button:hover {
    background-color: #0051BA;
    box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #ffffff 0%, #f0f0f0 100%);
}

.particle {
    position: absolute;
    width: 3px;
    height: 3px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    animation: move var(--duration) linear infinite;
}

@keyframes move {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translate(var(--x), var(--y));
        opacity: 0;
    }
}
</style>